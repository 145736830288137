import React, { useCallback, useContext, useEffect, useRef } from "react"
import { TransitionPortal } from "gatsby-plugin-transition-link";
import { gsap } from "gsap";

// import Layout from "../components/layout"
import SEO from "../components/seo"
import { aboutStyle } from "../components/aboutStyle";
import PageTitle from "../components/PageTitle/pageTitle";

import LogoKawashimaSelkonImg from "../images/logo_kawashimaselkon.svg";
import LogoKawashimaOrimonoImg from "../images/logo_kawashimaorimono.svg";
import LogoFritzHansenImg from "../images/logo_fritzhansen.svg";
import TransitionCover from "../components/TransitionCover/transitionCover";
import GlobalContext from "../context/globalContext";
import { duration, entryDelay } from "../constants/componentConstants";

const AboutPage = () => {

  const global = useContext(GlobalContext);
  useEffect(() => {
    global.setHideHeaderLogo(false);
    global.setEntryAnimation(entryAnimation);
    global.setExitAnimation(exitAnimation);
    // eslint-disable-next-line react-hooks/exhaustive-deps,
  }, []);

  const commonLayer = useRef(null);
  const entryAnimation = useCallback(() => {
    console.log("entryAnimation");
    gsap.timeline()
      .set(commonLayer.current, {
        xPercent: 100,
      })
      .to(commonLayer.current, {
        xPercent: 200,
        ease: "power2.in",
        duration: duration,
        delay: entryDelay,
        onComplete: () => {
          commonLayer.current.style.display = "none";
        }
      })
  }, []);
  const exitAnimation = useCallback(({ node }) => {
    console.log("exitAnimation");
    commonLayer.current.style.display = "block";
    gsap.timeline()
      .set(commonLayer.current, {
        xPercent: 0,
      })
      .to(commonLayer.current, {
        xPercent: 100,
        ease: "power2.out",
        duration: duration,
      })
      .to(node, {
        opacity: 0,
        duration: 0,
      })
  }, []);

  return (
    <>
      <SEO title="ABOUT US" />

      <div css={aboutStyle.aboutBlock}>
        <PageTitle style={aboutStyle.pageTitle}>ABOUT US</PageTitle>

        <div css={aboutStyle.aboutItemBlock}>
          <div css={aboutStyle.aboutItemLogoBlock}>
            <LogoKawashimaSelkonImg title="川島織物セルコン" />
          </div>
          <div css={aboutStyle.aboutItemDescription}>
            1843年に京都で創業した川島織物セルコンは、177年の歴史を持つ織物メーカー。古くは明治宮殿、近年では京都迎賓館や数々のラグジュアリーホテルに織物を納入するなど、その唯一無二のクオリティが高く評価されている。熟練の職人による伝統的な手織り技術に加えて、現代ならではのテクノロジーを駆使した機械織りも積極的に取り入れ、文化の継承と未来へつながる技術革新に力を注いでいる。京都の本社には、織物の製造工場をはじめ、歴史的価値の高い作品を所蔵する「川島織物文化館」や次世代に織物技術を継承する「川島テキスタイルスクール」を併設。織物文化の継承と発展、そして発信に努めている。
          </div>
          <div css={aboutStyle.aboutItemLinkBlock}>
            <a href="https://www.kawashimaselkon.co.jp/" target="_blank" rel="noopener noreferrer">
              WEB: www.kawashimaselkon.co.jp
            </a>
            <a href="https://www.instagram.com/kawashimaselkon_textiles/" target="_blank" rel="noopener noreferrer">
              INSTAGRAM: @kawashimaselkon_textiles
            </a>
          </div>
        </div>

        <div css={aboutStyle.aboutItemBlock}>
          <div css={aboutStyle.aboutItemLogoBlock}>
            <LogoKawashimaOrimonoImg title="川島織物" />
          </div>
          <div css={aboutStyle.aboutItemDescription}>
            軍配マークについて<br />
            <br />
            川島織物セルコンを象徴する軍配（ぐんばい）のモチーフは、かつて武将が戦の指揮に用いたうちわ形の道具が由来となっている。二代目の川島甚兵衞が、自らが織物の祖と仰ぐ聖徳太子ゆかりの軍配扇を京都の広隆寺で目にして感銘を受け、その図柄を商標に採用。現在も着物の帯に織り込むなど、歴史に裏付けされた織物技術と独自のデザイン表現、高品質の証として使用されている。本プロジェクトでは、ブランドの歴史や技術を発展させ、織物の未来につなげていくという志のもと、この軍配マークがそれぞれの作品に記されている。
          </div>
        </div>

        <div css={[aboutStyle.aboutItemBlock, aboutStyle.aboutItemFHBlock]}>
          <div css={[aboutStyle.aboutItemLogoBlock, aboutStyle.aboutItemFHLogoBlock]}>
            <LogoFritzHansenImg title="FRITZ HANSEN" />
          </div>
          <div css={aboutStyle.aboutItemDescription}>
            1872年にデンマークで創業したファニチャーブランド。時代に左右されることなく、世界的に有名なクラシックラインからコンテンポラリーラインまで、家具、照明、アクセサリー小物をオリジナリティあふれる世界観をもって表現。なかでも、1958年に誕生したブランドを代表する「エッグチェア（Egg™）」は、アルネ・ヤコブセンが残した不朽の名作で、60年以上経った今でも世界中の人々に愛されている。
          </div>
          <div css={aboutStyle.aboutItemLinkBlock}>
            <a href="https://fritzhansen.com/" target="_blank" rel="noopener noreferrer">
              WEB: fritzhansen.com
            </a>
          </div>
        </div>

      </div>

      <TransitionPortal>
        <TransitionCover ref={commonLayer} />
      </TransitionPortal>
    </>
  )
}

export default AboutPage
