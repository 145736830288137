import React from "react";
import { pageTitleStyle } from "./pageTitleStyle";

const PageTitle = ({ children, style = {} }) => {
  return (
    <h1 css={[pageTitleStyle.block, style]}>{children}</h1>
  );
}

export default PageTitle
