/** @jsx jsx */
import { css } from '@emotion/core';
import { color } from "../../constants/styleConstants";

export const transitionCoverStyle = {
  block: css`
    position: fixed;
    background: ${color.gold};
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    transform: translateX(-100%);
    z-index: 100;
    display: none;
  `,
}
