/** @jsx jsx */
import { css } from '@emotion/core';
import { mq, jpFont01 } from "../constants/styleConstants";

export const aboutStyle = {
  aboutBlock: css`
    max-width: 80rem;
    margin: 8rem auto 4rem;
    padding: 0 1.25rem;
    ${mq('medium')} {
      margin-top: 12rem;
      margin-bottom: 8rem;
    }
  `,
  pageTitle: css`
    display: none;
  `,
  aboutItemBlock: css`
    margin: 0 auto 5rem;
    max-width: 46.25rem;
    line-height: 2;
  `,
  aboutItemFHBlock: css`
    margin-top: 8rem;
  `,
  aboutItemLogoBlock: css`
    margin: 0 auto;
    width: 4rem;
    svg {
      width: 100%;
      height: auto;
    }
  `,
  aboutItemFHLogoBlock: css`
    width: 12rem;
    margin-bottom: 3rem;
  `,
  aboutItemDescription: css`
    ${jpFont01.medium};
    font-size: 0.875rem;
    margin-top: 2rem;
  `,
  aboutItemLinkBlock: css`
    ${jpFont01.medium};
    font-size: 0.875rem;
    margin-top: 2rem;
    a {
      display: block;
    }
  `,
}
