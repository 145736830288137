import React from "react";
import { transitionCoverStyle } from "./transitionCoverStyle";

const TransitionCover = React.forwardRef((props, ref) => {
  return (
    <div css={transitionCoverStyle.block} ref={ref}>
      {props.children}
    </div>
  );
});

export default TransitionCover
